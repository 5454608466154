import { render, staticRenderFns } from "./ExamType.vue?vue&type=template&id=564ba6c8"
import script from "./ExamType.vue?vue&type=script&lang=js"
export * from "./ExamType.vue?vue&type=script&lang=js"
import style0 from "./ExamType.vue?vue&type=style&index=0&id=564ba6c8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports