<template>
    <b-card-code>
        <div v-if="$can('create', 'Exam Type')" class="custom-search d-flex">
            <b-button variant="outline-primary" @click="showModal">
                {{ $t('Add new') }}
            </b-button>
        </div>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
            <b-form-group>
                <div class="d-flex align-items-center">
                    <label class="mr-1">{{ $t('Search') }}</label>
                    <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
                </div>
            </b-form-group>
        </div>

        <div class="custom-search d-flex justify-content-start">
            <vue-excel-xlsx :data="getData" :columns="columns10" :filename="'filename'" :sheetname="'subjects'">
                <b>Excel</b>
            </vue-excel-xlsx>
            <vue-json-to-csv :json-data="getData" :csv-title="'My_CSV'">
                <button>
                    <b>CSV</b>
                </button>
            </vue-json-to-csv>
            <button @click="pdfGenerate()">
                <b>PDF</b>
            </button>
        </div>
        <!-- table -->
        <vue-good-table style="white-space: nowrap;text-align: center;vertical-align : middle;" :columns="columns"
            :rows="exam_types" :rtl="direction" :search-options="{
                enabled: true,
                externalQuery: searchTerm
            }" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
            <template slot="table-row" slot-scope="props">
                <!-- Column: index -->
                <span v-if="props.column.field === 'id'">
                    {{ props.row.originalIndex + 1 }}
                </span>
                <!-- Column: Status -->
                <span v-else-if="props.column.field === 'is_active'">
                    <template>
                        <b-form-checkbox v-if="$can('status change', 'Subject')"
                            :name="'check-button' + props.row.originalIndex"
                            :id="'check-button' + props.row.originalIndex"
                            @change="statusChange(props.row.id, props.row.originalIndex)" v-model="props.row.is_active"
                            :value="1" switch>
                        </b-form-checkbox>
                        <b-badge v-else :variant="statusVariant(props.row.is_active)">
                            {{ props.row.is_active ? 'Active' : 'Deactivated' }}
                        </b-badge>
                    </template>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                    <span>
                        <template>
                            <!-- modal login button -->
                            <b-button v-if="$can('update', 'Exam Type')" @click="selectedRow(props.row)"
                                v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-login variant="primary">
                                <feather-icon icon="Edit2Icon" class="mr-50" />
                            </b-button>
                            <b-button v-if="$can('remove', 'Exam Type')" variant="danger" @click="remove(props.row.id)">
                                <feather-icon icon="TrashIcon" class="mr-50" />
                            </b-button>
                        </template>
                    </span>
                </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
                <div class="d-flex justify-content-between flex-wrap">
                    <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap ">
                            Showing 1 to
                        </span>
                        <b-form-select v-model="pageLength" :options="['10', '15', '20']" class="mx-1"
                            @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                        <span class="text-nowrap"> of {{ props.total }} entries </span>
                    </div>
                    <div>
                        <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number
                            last-number align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                            @input="(value) => props.pageChanged({ currentPage: value })">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </div>
                </div>
            </template>
        </vue-good-table>
        <!-- Examination modification modal screen.-->
        <b-modal ref="my-modal" size="lg" hide-footer
            :title="model_mode === 'add' ? $t('New Exam Type') : $t('Modify Exam Type')">
            <!-- form -->
            <validation-observer ref="simpleRules">
                <b-form ref="add_or_update_form">
                    <b-row>
                        <b-col md="4">
                            <b-form-group>
                                <b-card-text>
                                    <span>{{ $t('Exam type name') }} </span>
                                </b-card-text>
                                <validation-provider #default="{ errors }" :name="$t('Exam type')" rules="required">
                                    <b-form-input v-model="name" name="name" :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('Exam type')" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group>
                                <b-card-text>
                                    <span>{{ $t('Mark types') }} </span>
                                </b-card-text>
                                <validation-provider #default="{ errors }" :name="$t('mark types')" rules="required">
                                    <b-form-tags v-model="mark_types" name="mark_types[]"
                                        :state="errors.length > 0 ? false : null"
                                        :placeholder="$t('mark type likes MCQ,CQ,Tutorial, etc')" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <b-col md="4">
                            <b-form-group>
                                <b-card-text>
                                    <span>{{ $t('Mark percent in agreed result') }} </span>
                                </b-card-text>
                                <validation-provider #default="{ errors }" :name="$t('mark % add in agreed result')"
                                    rules="required">
                                    <b-form-input v-model.number="mark_percent_add_in_agreed_result"
                                        name="mark_percent_add_in_agreed_result"
                                        :state="errors.length > 0 ? false : null" type="number"
                                        :placeholder="$t('mark % add in agreed result')" />
                                    <small class="text-danger">{{ errors[0] }}</small>
                                </validation-provider>
                            </b-form-group>
                        </b-col>
                        <!-- submit button -->
                        <b-col md="4">
                            <b-button style="vertical-align: middle;" variant="primary" type="submit"
                                @click.prevent="saveExaminationType">
                                Submit
                            </b-button>
                        </b-col>
                    </b-row>
                </b-form>
            </validation-observer>
        </b-modal>

    </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BButton, BPagination, BFormGroup, BFormInput, BFormSelect,
    BModal, BForm, BRow, BCol, BCardText, BFormTags, BFormCheckbox, BBadge,
} from 'bootstrap-vue'
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";

Vue.use(VueExcelXlsx);
import { required } from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from "vuex";

export default {
    name: 'ExamType',
    components: {
        BCardCode,
        VueGoodTable,
        BPagination, BBadge,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BButton, BFormTags,
        BModal, BCardText,
        BForm, BRow, BCol, BFormCheckbox,
        ValidationProvider,
        ValidationObserver, VueJsonToCsv
    },
    directives: {
        Ripple,
    },
    data() {
        return {
            name: '',
            classes_id: null,
            version_id: null,
            student_group_id: null,
            mark_types: [],
            mark_percent_add_in_agreed_result: 100,
            grade_type_id: null,
            academic_year_id: null,
            required,
            pageLength: 10,
            dir: false,
            model_mode: 'add',
            selected_row: {},
            columns: [
                {
                    label: 'SL',
                    field: 'id',
                },
                {
                    label: 'Name',
                    field: 'name',
                },
                {
                    label: 'Mark Types',
                    field: 'mark_types',
                },
                {
                    label: 'MPR Add in AR',
                    field: 'mark_percent_add_in_agreed_result',
                },
                {
                    label: 'Status',
                    field: 'is_active',
                },
                {
                    label: 'Action',
                    field: 'action',
                },
            ],
            searchTerm: '',
            status: [{
                1: 'active',
                2: 'deactivate',
            },
            {
                1: 'light-primary',
                2: 'light-danger',
            }],
            classes: [],
            versions: [],
            student_groups: [],
            columns10: [
                {
                    label: 'Name',
                    field: 'Name',
                },
                {
                    label: 'Marks Type',
                    field: 'MarksType',
                },
                {
                    label: 'MPR Add in AR',
                    field: 'MPRAddinAR',
                },
                {
                    label: 'Status',
                    field: 'Status',
                },
            ],
        }
    },
    methods: {
        jsonParse(data) {
            try {
                return JSON.parse(data);
            } catch (e) {
                return [];
            }
        },
        selectedRow(row) {
            this.selected_row = row;
            this.name = row.name;
            // this.classes_id = row.classes_id;
            // this.version_id = row.version_id;
            // this.student_group_id = row.student_group_id;
            this.mark_types = this.jsonParse(row.mark_types);
            this.mark_percent_add_in_agreed_result = row.mark_percent_add_in_agreed_result;
            // this.grade_type_id = row.grade_type_id;
            // this.academic_year_id = row.academic_year_id;
            this.model_mode = 'edit';
            this.$refs['my-modal'].show()
        },
        saveExaminationType() {
            this.$refs.simpleRules.validate().then(success => {
                if (success) {
                    let data = new FormData(this.$refs.add_or_update_form);
                    if (this.model_mode === 'add') {
                        apiCall.post('/exam/type/store', data).then((response) => {
                            if (response.data.status === 'success') {
                                this.hideModal();
                                this.$store.commit('ADD_EXAM_TYPE', response.data.data);
                                this.$toaster.success(response.data.message);
                            } else this.$toaster.error(response.data.message);
                        }).catch((error) => {
                            if (error.response.status == 422) {
                                Object.keys(error.response.data.errors).map((field) => {
                                    this.$toaster.error(error.response.data.errors[field][0]);
                                })
                            } else this.$toaster.error(error.response.data.message);
                        });
                    } else {
                        apiCall.post(`/exam/type/update${this.selected_row.id}`, data).then((response) => {
                            if (response.data.status === 'success') {
                                this.hideModal();
                                this.$toaster.success(response.data.message);
                                this.$store.dispatch('GET_ALL_EXAM_TYPE');
                            } else this.$toaster.error(response.data.message);

                        }).catch((error) => {
                            if (error.response.status == 422) {
                                Object.keys(error.response.data.errors).map((field) => {
                                    this.$toaster.error(error.response.data.errors[field][0]);
                                })
                            } else this.$toaster.error(error.response.data.message);
                        });
                    }
                }
            })
        },
        pdfGenerate() {
            /*only change able*/
            let title = "All Exam list";
            let clm = ['Name', 'Version', 'ClassName', 'StudentGroup', 'MarksType', 'MPRAddinAR', 'GradeType', 'AcademicYear', 'Status'];
            /*change able end*/
            let data = new FormData();
            data.append('title', title);
            data.append('columns', JSON.stringify(clm));
            data.append('data', JSON.stringify(this.getData));
            apiCall.post('/get/common/table/export/pdf', data, { responseType: 'blob' }).then((response) => {
                const content = response.headers['content-type'];
                download(response.data, 'examination_types.pdf', content)
            }).catch((error) => {
                this.$toaster.error(error.response.data.message);
            });
        },
        /*
        *
        * method for status change item
        * */
        statusChange(id, index) {
            apiCall.put(`/exam/type/status/change${id}`).then((response) => {
                if (response.data.status === 'success') {
                    this.$toaster.success(response.data.message);
                    this.$store.commit('STATUS_CHANGE_EXAM_TYPE', index);
                } else {
                    this.$store.dispatch('GET_ALL_EXAM_TYPE');
                }
            }).catch((error) => {
                this.$toaster.error(error.response.data.message);
            });
        },
        remove(id, index) {
            this.$swal({
                title: 'Are you sure?',
                text: "You want to delete this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, delete it!',
                customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-danger ml-1',
                },
                buttonsStyling: false,
            }).then(result => {
                if (result.value) {
                    apiCall.delete(`/exam/type/remove${id}`).then((response) => {
                        this.$store.commit('REMOVE_EXAM_TYPE', index);
                        this.$swal({
                            icon: 'success',
                            title: 'Deleted!',
                            text: response.data.message,
                            customClass: {
                                confirmButton: 'btn btn-success',
                            },
                        })
                    }).catch((error) => {
                        this.$toaster.error(error.response.data.message);
                    });
                } else if (result.dismiss === 'cancel') {
                    this.$swal({
                        title: 'Cancelled',
                        text: 'Your date is safe :)',
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                    })
                }
            })
        },
        showModal() {
            this.selected_row = {};
            this.name = '';
            // this.classes_id = null;
            // this.version_id = null;
            // this.student_group_id = null;
            this.mark_types = [];
            this.mark_percent_add_in_agreed_result = 100;
            // this.grade_type_id = null;
            // this.academic_year_id = null;
            this.model_mode = 'add';
            this.$refs['my-modal'].show()
        },

        hideModal() {
            this.$refs['my-modal'].hide()
        },
        /*method for get all classes*/
        getClasses() {
            apiCall.get('/get/active/class/with/v/s/sg/wings').then((response) => {
                this.classes = response.data;
            }).catch(() => {
                this.classes = [];
            })
        }
    },
    computed: {
        statusVariant() {
            const statusColor = {
                /* eslint-disable key-spacing */
                1: 'light-success',
                0: 'light-danger',
                /* eslint-enable key-spacing */
            }

            return status => statusColor[status]
        },
        direction() {
            if (store.state.appConfig.isRTL) {
                // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                this.dir = true
                return this.dir
            }
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.dir = false
            return this.dir
        },
        ...mapGetters(['exam_types', 'academic_years', 'grade_types', 'versionByID', 'classByID', 'student_groupByID',
            'academic_yearByID', 'gradeTypeByID']),
        vs() {
            return this.$store.getters.versions;
        },
        cls() {
            return this.$store.getters.classes;
        },
        stg() {
            return this.$store.getters.student_groups;
        },
        getData() {
            return this.exam_types.map(item => ({
                Name: item.name,
                // Version: item.version_id ? this.versionByID(item.version_id) : '',
                ClassName: item.classes_id ? this.classByID(item.classes_id) : '',
                // StudentGroup: item.student_group_id ? this.student_groupByID(item.student_group_id) : '',
                MarksType: item.mark_types,
                MPRAddinAR: item.mark_percent_add_in_agreed_result,
                GradeType: item.grade_type_id ? this.gradeTypeByID(item.grade_type_id) : '',
                AcademicYear: item.academic_year_id ? this.academic_yearByID(item.academic_year_id) : '',
                Status: item.is_active ? 'Active' : 'Inactive'
            }));
        }
    },
    created() {
        this.$store.dispatch('GET_ALL_EXAM_TYPE');
        this.getClasses();
    },
    watch: {
        classes_id() {
            if (this.classes_id) {
                if (this.modal_mode === 'add') {
                    this.versions = [];
                    this.student_groups = [];
                    this.version_id = null;
                    this.student_group_id = null;
                }
                let cls = this.classes.find(item => item.id === this.classes_id);
                if (cls) {
                    this.versions = cls.versions;
                    this.student_groups = cls.student_groups;
                }
            } else {
                this.versions = [];
                this.version_id = null;
                this.student_group_id = null;
                this.student_groups = [];
            }
        }
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>